<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <div style="width: 100px">
                <v-autocomplete
                        v-model="lang"
                        :rules="[() => !!lang || 'This field is required']"
                        :items="languages"
                        label="Lang"
                        required
                        solo
                        hide-details
                        dense
                />
            </div>
            <v-btn
                color="primary"
                elevation="2"
                @click="openForm(null, createServiceObject)"
            >Add service</v-btn>
        </div>

        <v-card
            class="mx-auto my-4"
            v-for="service in services"
            :key="service.id"
        >

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Service
                        </th>
                        <th class="text-right">
                            <template>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openForm(service, createServiceObject)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="info">
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="deleteItem(service)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="red">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </template>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="font-weight-medium">Order</td>
                      <td>{{ service.order }}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Title</td>
                        <td>{{ lang === 'uz' ? service.titleUz : lang === 'ru' ? service.titleRu : lang === 'en' ? service.titleEn : ''}}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Description</td>
                        <td>{{ lang === 'uz' ? service.descriptionUz : lang === 'ru' ? service.descriptionRu : lang === 'en' ? service.descriptionEn : ''}}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Content</td>
                        <td v-html="lang === 'uz' ? service.contentUz : lang === 'ru' ? service.contentRu : lang === 'en' ? service.contentEn : ''"></td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">bigImg</td>
                        <td>
                            <div class="service__img">
                                <img :src="baseURL + service.bigImg"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">smallImg</td>
                        <td>
                            <div class="service__img">
                                <img :src="baseURL + service.smallImg"/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div class="py-2 d-flex justify-end">
                <v-btn  color="primary"
                        elevation="2"
                        block
                        @click="openForm(service, createServiceChildObject)"
                >Add child service</v-btn>
            </div>

            <v-btn block
                   color="success"
                   @click="toggleService(service.id)"
            >
                <div>
                    Child Services
                </div>

                <v-spacer/>

                <v-btn
                    icon
                    color="white"
                >
                    <v-icon>{{ showService === service.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-btn>

            <v-expand-transition>
                <div v-show="showService === service.id">
                    <v-divider/>

                    <v-card
                            class="mx-auto my-3"
                            v-for="childService in getChildServices"
                            :key="childService.id"
                    >

                        <v-simple-table dark>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Child service
                                    </th>
                                    <th class="text-right">
                                        <template>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="openForm(childService, createServiceChildObject)"
                                                           v-on="on"
                                                           v-bind="attrs"
                                                           icon color="info">
                                                        <v-icon small>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Edit</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="deleteItem(childService)"
                                                           v-on="on"
                                                           v-bind="attrs"
                                                           icon color="red">
                                                        <v-icon small>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </template>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td class="font-weight-medium">Order</td>
                                  <td>{{ childService.order }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-medium">Title</td>
                                    <td v-html="lang === 'uz' ? childService.titleUz : lang === 'ru' ? childService.titleRu : lang === 'en' ? childService.titleEn : ''"></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-medium">Description</td>
                                    <td v-html="lang === 'uz' ? childService.descriptionUz : lang === 'ru' ? childService.descriptionRu : lang === 'en' ? childService.descriptionEn : ''"></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-medium">Content</td>
                                    <td v-html="lang === 'uz' ? childService.contentUz : lang === 'ru' ? childService.contentRu : lang === 'en' ? childService.contentEn : ''"></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-medium">bigImg</td>
                                    <td>
                                        <div class="service__img">
                                            <img :src="baseURL + childService.bigImg"/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-medium">smallImg</td>
                                    <td>
                                        <div class="service__img">
                                            <img :src="baseURL + childService.smallImg"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-card>
                </div>
            </v-expand-transition>
        </v-card>

        <Modal v-model="modal"
               width="1140px"
               title="Service form">
            <Form v-if="modal"
                  :data-object="dataObject"
                  @close-modal="modal = false"
                  @send-service="sendService"
            />
        </Modal>
    </div>
</template>

<script>
    import Form from "./Form";
    import formModal from "@/mixins/formModal";
    import deleteModal from "@/mixins/deleteModal";
    import { baseURL } from "@/services/api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "Services",
        data: () => ({
            baseURL,
            showService: 0,
            lang: 'uz',
            languages: ['uz', 'ru', 'en'],
            services: [],
            childService: {}
        }),
        components: { Form },
        computed: {
            ...mapGetters({
                getServices: 'getServices',
                getChildServices: 'getChildServices',
            })
        },
        mixins: [formModal, deleteModal],
        methods: {
            ...mapActions({
                fetchServices: 'fetchServices',
                fetchChildServices: 'fetchChildServices',
                creatService: 'creatService',
                updateService: 'updateService',
                deleteService: 'deleteService',
            }),

            async toggleService(id) {
                const isService = this.getChildServices.every(service => service.ServiceId === id);

                if (!isService || (isService && this.getChildServices.length === 0)) { await this.fetchChildServices(id); }

                if (this.getChildServices.length === 0) { this.showService = 0; return; }

                if (this.showService !== id) { this.showService = id; return }
                this.showService = 0;
            },

            createServiceObject (service = null) {
                const object = {
                    order: service ? service.order : '',
                    titleUz: service ? service.titleUz : '',
                    titleRu: service ? service.titleRu : '',
                    titleEn: service ? service.titleEn : '',
                    descriptionUz: service ? service.descriptionUz : '',
                    descriptionRu: service ? service.descriptionRu : '',
                    descriptionEn: service ? service.descriptionEn : '',
                    contentUz: service ? service.contentUz : '',
                    contentRu: service ? service.contentRu : '',
                    contentEn: service ? service.contentEn : '',
                };
                if (service) {
                    object.id = service.id;
                } else {
                    object.smallImg = null;
                    object.bigImg = null;
                }
                return object;
            },

            createServiceChildObject (service) {
                if (!service.ServiceId) {
                    this.childService = this.createServiceObject(null);
                    this.childService.ServiceId = service.id;
                } else {
                    this.childService = this.createServiceObject(service);
                    this.childService.ServiceId = service.ServiceId;
                }

                return this.childService;
            },

            async sendService(data) {
                if (this.dataObject.id) {
                    await this.updateService(data);
                } else {
                    await this.creatService(data);
                }

                if (this.dataObject.ServiceId) {
                    await this.fetchChildServices(this.dataObject.ServiceId);
                }

                await this.fetchServices();
                this.modal = false;
            },

            async deleteItem(service) {
                await this.deleteService(service.id);
                await this.fetchServices();

                if (service.ServiceId) {
                  await this.fetchChildServices(service.ServiceId);
                }
            }
        },
        watch: {

        },
        async mounted() {
            await this.fetchServices();
            this.services = this.getServices.filter(service => service.id !== 68)
        }
    }
</script>

<style scoped lang="scss">
    .service__img {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;

        & img {
            height: 100px;
        }
    }
</style>
